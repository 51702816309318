
          $PRIMARY-COLOR: #32AE89;
          $AUTH-BG-GRADIENT-0: #59D1AE;
          $AUTH-BG-GRADIENT-100: #2CA581;
        

.contextmenu {
  margin: 0;
  background: #fff;
  z-index: 1030;
  position: fixed;
  list-style-type: none;
  padding: 5px 0;
  width: 300px;
  border-radius: 2px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);

  li {
    margin: 0;
    padding: 7px 16px;
    cursor: pointer;

    &:hover {
      background: #eee;
    }
  }
}
.toolbar {
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
}

.container {
  background-color: white;
  max-width: calc(100vw - 64px);
  max-height: calc(100vh - 140px);
  overflow: auto;
}

.title-input {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 18px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  background: none;
  border: none;
  outline: none;
  resize: none;
  z-index: 99999;
}
