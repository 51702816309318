
          $PRIMARY-COLOR: #32AE89;
          $AUTH-BG-GRADIENT-0: #59D1AE;
          $AUTH-BG-GRADIENT-100: #2CA581;
        

.folders-conteiner {
  height: 40vh;
  overflow-y: auto;
}

.advanced-filter .b-form-tag {
  font-size: 85%;
}

.folders-header {
  border-top: 1px solid #eef2f7;
  border-bottom: 2px solid #eef2f7;
  background-color: #fafbfe;

  .folders-title {
    margin: 8px 0;
    font-weight: bold;
  }
}

.folder-item {
  padding: 0.3rem;
  border: dotted #d2d7dd 1px;
  background-color: #fefefe;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 3px;

  &:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(244 245 246 / var(--tw-bg-opacity));
  }
}

.item-active {
  background-color: #e7ebf0;
  color: rgb(214, 120, 120);

  &:hover {
    background-color: #e7ebf0;
  }
}
