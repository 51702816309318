
          $PRIMARY-COLOR: #32AE89;
          $AUTH-BG-GRADIENT-0: #59D1AE;
          $AUTH-BG-GRADIENT-100: #2CA581;
        

.list-group {
  max-height: 50vh;
  overflow-y: auto;
  padding-left: 0.85rem;
  padding-right: 0.85rem;
}

.field-item {
  padding: 0.35rem;
  border: solid #adb5bd 1px;
  background-color: #fefefe;
  border-radius: 0.25rem;
  font-size: 16px;
  cursor: pointer;
}
.item-active {
  background-color: #dee2e6;
}
